import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"

const PageHeader = ({ lang, location, title, author, featuredImage }) => {

	const header = featuredImage ? (
		<>
			<header className="page-header image mb-16">
			<GatsbyImage image={featuredImage.childImageSharp.gatsbyImageData} alt={title} className="absolute top-0 inset-x-0 w-screen h-screen" />
			<div className="heading">
				<h1 className="major text-white">{title}</h1>
			</div>
		</header>
		</>
	) : (
		<>
			<header className="page-header py-20">
			<div className="heading">
				<h1 className="major">{title}</h1>
			</div>
		</header>
		</>
	);

	return (
		<>
			{header}
		</>
	)
};

export default PageHeader;