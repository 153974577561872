import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/common/layout";
import Artists from "components/artists/Artists";
import PageHeader from "../components/pageheader";

const ArtistPage = ({ data, pageContext, location, lang }) => {
	const { post } = pageContext

	const language = lang;
	let isEn = false;
	if (language === "en") {
		isEn = true;
	}
	const content = {
		artistPanel: { en: "About the Artists and their work", is: "Um listamennina" },
	};

return (
  <Layout location={location} lang={lang}>

			<section id="main" className="wrapper">
			<PageHeader
						lang={lang}
						location={location}
						title={post.frontmatter.name}
						author={post.frontmatter.author}
						featuredImage={post.frontmatter?.featuredImage}
					/>
    <div id="wrapper">
				<div className="page-content">
          {/* <span className="image fit">
                        <img src={pic4} alt="" />
                    </span> */}
					<div
            dangerouslySetInnerHTML={{
              __html: post.html
						}}
          ></div>
        </div>
			</div>
      </section>
		<div className="more-items-panel">
			<h2>{isEn ? content.artistPanel.en : content.artistPanel.en}</h2>
		<Artists artists={data.natureintransitionJson.artists} />
		</div>
  </Layout>
);};

export default ArtistPage;

export const artistQuery = graphql`
         query($slug: String!) {
           site {
             siteMetadata {
               title
             }
           }
           markdownRemark(frontmatter: { slug: { eq: $slug } }) {
             id
             html
             frontmatter {
               slug
               title
               path
               name
               country
							 featuredImage {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							 }
             }
           }
           natureintransitionJson {
             title
             artists {
               name
               country
               to {
                 title
                 href
                 label
               }
             }
           }
         }
       `;